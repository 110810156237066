<template>
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="30" height="30" rx="15" fill="url(#paint0_linear_2047_1447)"/>
        <path d="M12.7138 21L7 15.2863L8.14522 14.1411L12.7138 18.71L22.4242 9L23.5694 10.1452L12.7138 21Z"
              fill="white"/>
        <defs>
            <linearGradient id="paint0_linear_2047_1447" x1="28.8" y1="2.4" x2="-3.86238e-07" y2="36.6"
                            gradientUnits="userSpaceOnUse">
                <stop stop-color="#39C0ED"/>
                <stop offset="0.473958" stop-color="#6D87FC"/>
                <stop offset="1" stop-color="#C4F0FF"/>
            </linearGradient>
        </defs>
    </svg>

</template>

<script>
export default {
    name: "ConfirmationIcon"
}
</script>

<style>

</style>
