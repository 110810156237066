<template>
  <login-layout>
    <div class="row">
      <div class="col-lg-6 loginPage__title">
        {{ $t("loginPageCompanyName")
        }}<span class="purple">{{ $t("platform") }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-5 loginPage__subTitle">
        {{ $t("loginPageSubtitleText") }}
      </div>
    </div>
    <div v-if="step.mail">
      <div class="row">
        <div class="col-lg-6 forgotPasswordPage">Forgot your password?</div>
      </div>
      <div class="row">
        <div class="col-lg-6 forgotPasswordPage__inputTitle">
          Enter your email to reset your password.
        </div>
      </div>
      <div class="loginPage__mb-50">
        <base-input class="col-lg-4" v-model="userMail" type="text"
          >Enter your email
        </base-input>
      </div>
      <div class="w-max-content">
        <base-button :calendar="true" @click="resetPassword()"
          >Reset password
        </base-button>
      </div>
    </div>
    <div v-if="step.confirmation">
      <div class="registrationPage__confirmationWrapper">
        <div class="d-flex align-items-center mb-10">
          <confirmation-icon class="me-10" />
          We have sent you an email at &nbsp;
          <span class="blue"> {{ enterMail }}</span>
        </div>
        <div class="loginPage__mb-50">
          Please, check out your inbox for an email from Aura Impact Platform
          and follow the link to reset your password.
        </div>
        <div class="mb-10">Didn’t receive a message?</div>
        <ul class="registrationPage__confirmationList">
          <li>- Check the correctness of the entered email</li>
          <li>- Check your mailbox spam</li>
          <li>- Click Resend email.</li>
        </ul>
      </div>
      <base-button
        v-if="resendMailBtnState"
        @click="resendMail()"
        :calendar="true"
        >Resend email
      </base-button>
    </div>
    <div v-if="step.wrongToken">
      <div>Something went wrong</div>
      <div>Ann error occured during account activation.</div>
      <div>
        Please, follow the link from email one more time or contact Aura Impact
        Support.
      </div>
      <a href="mailto:support@aura-impact.com">
        <base-button class="forgotPassword__contact-btn">
          {{ $t("sidebarContactSupportBtn") }}
        </base-button>
      </a>
    </div>
    <div v-if="step.successesToken" class="col-lg-4 col-xxl-4">
      <div class="position-relative">
        <base-input
          class="passwordResetPopup__input registrationPage__input"
          :error="v$.password.$error"
          :error-message="v$.password.$errors"
          v-model="password"
          :maxlength="32"
          :type="passwordInputType"
          >Enter new password*
        </base-input>
        <div
          class="registrationPage__passwordIcon"
          @click="showPassword = !showPassword"
        >
          <show-password-icon />
        </div>
      </div>
      <div class="position-relative">
        <base-input
          class="passwordResetPopup__input registrationPage__input mb-5"
          :error="v$.repeatPassword.$error"
          :error-message="v$.repeatPassword.$errors"
          v-model="repeatPassword"
          :maxlength="32"
          :type="passwordInputType"
          >Repeat new password*
        </base-input>
        <div
          class="registrationPage__passwordIcon"
          @click="showPassword = !showPassword"
        >
          <show-password-icon />
        </div>
      </div>
      <base-button class="passwordResetPopup-submit" @click="changePassword()"
        >Change password
      </base-button>
    </div>
  </login-layout>
</template>

<script>
import LoginLayout from "@/layouts/LoginLayout";
import BaseInput from "@/components/UI/inputs/BaseInput";
import BaseButton from "@/components/UI/buttons/BaseButton";
import ConfirmationIcon from "@/components/UI/icons/buttonIcons/ConfirmationIcon.vue";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import ShowPasswordIcon from "@/components/UI/icons/ShowPasswordIcon.vue";
import useVuelidate from "@vuelidate/core";
import { required, sameAs } from "@vuelidate/validators";

export default {
  name: "ForgotPassword",
  components: {
    ShowPasswordIcon,
    ConfirmationIcon,
    BaseButton,
    BaseInput,
    LoginLayout,
  },
  setup() {
    const toast = useToast();
    const v$ = useVuelidate();

    return {
      toast,
      v$,
    };
  },
  data() {
    return {
      showPassword: false,
      userMail: null,
      resendMailBtnState: true,
      password: null,
      repeatPassword: null,
      step: {
        mail: true,
        confirmation: false,
        wrongToken: false,
        successesToken: false,
      },
    };
  },
  validations() {
    return {
      password: { required, sameAs: sameAs(this.repeatPassword) },
      repeatPassword: { required, sameAs: sameAs(this.password) },
    };
  },
  mounted() {
    this.userMail = this.enterMail;
    if (this.$route.query.token) {
      this.checkToken(this.$route.query.token);
    }
    window.addEventListener("keyup", (event) => {
      if (event.keyCode === 13) {
        if (this.step.mail) {
          this.resetPassword();
        }
        if (this.step.successesToken) {
          this.changePassword();
        }
      }
    });
  },
  methods: {
    checkToken(token) {
      this.$noAuth
        .get(`users/verify-password-token/${token}`)
        .then(() => {
          this.setStep("successesToken");
        })
        .catch(() => {
          this.setStep("wrongToken");
        });
    },
    setStep(key) {
      for (let i in this.step) {
        this.step[i] = key === i;
      }
    },
    resetPassword() {
      this.$noAuth
        .post("/users/forgot-password", {
          email: this.enterMail,
        })
        .then(() => {
          this.setStep("confirmation");
        })
        .catch((err) => {
          if (err.response.data.status === 400) {
            this.toast.error(err.response.data.message);
          } else {
            this.toast.error(this.$t("NetworkError"));
          }
        });
    },
    changePassword() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.toast.error(this.$t("checkInputs"));
        return;
      }
      this.$noAuth
        .patch("users/restore-password", {
          password: this.password,
          token: this.$route.query.token,
        })
        .then(() => {
          this.toast.success("PasswordSuccessfullyChange");
          this.$router.push("/login");
        })
        .catch((err) => {
          if (err.response.data.status === 400) {
            this.toast.error(err.response.data.message);
          } else {
            this.toast.error(this.$t("NetworkError"));
          }
        });
    },
    resendMail() {
      this.$noAuth
        .post("/users/forgot-password", {
          email: this.enterMail,
        })
        .then(() => {
          this.resendMailBtnState = false;
          this.toast.success(this.$t("emailSuccessesSend"));
        })
        .catch((err) => {
          if (err.response.data.status === 400) {
            this.toast.error(err.response.data.message);
          } else {
            this.toast.error(this.$t("NetworkError"));
          }
        });
    },
  },
  computed: {
    ...mapGetters({
      enterMail: "MAIL",
    }),
    passwordInputType() {
      return this.showPassword ? "text" : "password";
    },
  },
  watch: {
    userMail(value) {
      this.$store.commit("SET_MAIL", value);
    },
  },
};
</script>

<style></style>
